<template>
  <div>
    <el-dropdown
      v-for="(item, index) in showFields"
      :key="index"
      size="small"
      placement="bottom-start"
      class="sel-drop"
      @command="(c) => handleCommand(c, index)"
    >
      <p class="model-p dp-flex align-item-c">
        <span :class="['small-circle', index == 0 ? 'bg-theme-blue' : 'bg-success']"></span>
        <span
          :class="['small-circle', index == 0 ? 'bg-theme-blue' : 'bg-success']"
        ></span>
        <span
          >{{ getName(item) }}<i class="el-icon-arrow-down el-icon--right"></i
        ></span>
      </p>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(f, fi) in hasDisableFilteredFields"
          :key="fi"
          :command="f.keyName"
          :disabled="f.disabled"
          >{{ f.name }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapState("chartDrawer", ["showFields"]),
    ...mapGetters("chartDrawer", ["hasDisableFilteredFields"]),
  },
  methods: {
    async handleCommand(c, index) {
      let arr = [...this.showFields];
      let obj = this.hasDisableFilteredFields.find((item) => item.keyName == c);
      arr[index] = { ...obj };
      await this.$store.dispatch("chartDrawer/setShowFields", { fields: arr });
      this.$emit("indicator-change")
    },
    getName(item){
      return  this.hasDisableFilteredFields.find(f => f.keyName == item.keyName)?.name || '--'
    }
  }
};
</script>

<style></style>
