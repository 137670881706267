<template>
  <div class="pane-content bg-f">
    <div class="chart-area py-16 px-20">
      <el-tabs
        class="gender-age-tab"
        v-model="genderAgeTab"
        type="card"
        @tab-click="handleTabClick"
      >
        <el-tab-pane
          v-for="(item, index) in genderAgeTabs"
          :key="index"
          :label="item.title"
          :name="item.value"
        ></el-tab-pane>
      </el-tabs>
      <div class="dp-flex align-item-c">
        <!-- 展示指标 -->
        <show-indicator></show-indicator>
      </div>
      <!-- chart-性别年龄 -->
      <!-- <gender-age-chart
        v-if="genderAgeTab == 'gender_age' && xAxisData.length"
        :colors="colors"
        :chartData="chartData"
        :xAxisData="xAxisData"
        :fields="showFields"
        :mark="genderAgeTab"
      ></gender-age-chart> -->
      <!-- chart-性别 -->
      <!-- <gender-chart
        v-if="genderAgeTab == 'gender' && xAxisData.length"
        :colors="colors"
        :chartData="chartData"
        :xAxisData="xAxisData"
        :fields="showFields"
        :mark="genderAgeTab"
      ></gender-chart> -->
      <!-- chart-年龄 -->
      <!-- <age-chart
        v-if="genderAgeTab == 'age' && xAxisData.length"
        :colors="colors"
        :chartData="chartData"
        :xAxisData="xAxisData"
        :fields="showFields"
        :mark="genderAgeTab"
      ></age-chart> -->
      <keep-alive>
        <components
          v-if="xAxisData.length"
          :is="activeTabComponent"
          :colors="colors"
          :chartData="chartData"
          :xAxisData="xAxisData"
          :fields="showFields"
          :mark="genderAgeTab"
        ></components>
      </keep-alive>
    </div>
    <div class="blank bg-theme"></div>
    <!-- table 报表 -->
    <div class="table-area px-20">
      <div class="title py-16 dp-flex justify-btw align-item-c">
        <span>数据报表</span>
        <div class="dp-flex align-item-c cursor-pointer">
          <div class="downLoad mr-10">
            <down-load
              :downLoadParams="downLoadParams"
              tipContent="点击下载报表"
            ></down-load>
          </div>
          <!-- <p @click="openCusColumn" class="dp-flex align-item-c">
            <i class="el-icon-s-grid"></i><span>列</span>
          </p> -->
        </div>
      </div>
      <!-- table -->
      <cus-table
        ref="tableRef"
        :tableParams="tableParams"
        :tableData="createTableData"
        :colData="colData(fixedFields)"
        :cuscolOtherOption="{ fields: filteredFields }"
        :getSummaries="getSummaries"
        :showSummary="true"
      >
        <template v-slot:colslot="{ scope, prop, otherOption }">
          <!-- <div v-if="prop == 'product_report'">
            <ad-cell-product-report
              :value="scope"
              :key="scope.row.level + scope.row.id + scope.row.name"
            ></ad-cell-product-report>
          </div>
          <div v-else-if="prop != 'budget'"> -->
          <div v-if="prop != 'budget'">
            {{
              cellFormat(
                scope.row[prop],
                scope.row[prop + "_desc"],
                prop,
                otherOption
              )
            }}
          </div>
        </template>
      </cus-table>
      <!-- 定制列 -->
      <cus-column-dialog
        :visible="cusColumnVisible"
        :initCheckedColumns="filteredFields.map(item => item.keyName)"
        :isSearchOpt="false"
        :hasFilterFields="true"
        @close-dialog="cusColumnVisible = false"
        @post-cus-columns="handleGetCusColumns"
      ></cus-column-dialog>
    </div>
  </div>
</template>

<script>
import showIndicator from "../showIndicator";
import genderAgeChart from "../chart/genderAgeChart";
import genderChart from "../chart/genderChart";
import ageChart from "../chart/ageChart";
import downLoad from "@/views/adManagement/components/tableTopBar/downLoad.vue";
import cusColumnDialog from "@/views/adManagement/components/cusColumnDialog/index";
import cusTable from "@/views/components/cusTable";
import { breakdown, getAxisData } from "@/api/adManagement";
import { mapState, mapGetters } from "vuex";
// 经营评估
import AdCellProductReport from "@/views/adManagement/components/table/AdCellProductReport";
export default {
  components: {
    showIndicator,
    downLoad,
    cusColumnDialog,
    cusTable,
    genderAgeChart,
    genderChart,
    ageChart,
    AdCellProductReport
  },
  props: {
    // 时间范围
    dateRange: {
      type: Array,
      default: () => []
    },
    curSelNode: {
      type: Object,
      default: () => {}
    },
    getSummaries: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    cellFormat: {
      type: Function,
      default: () => {
        return () => {};
      }
    }
  },
  data() {
    return {
      chartData: [],
      xAxisData: [],
      cusColumnVisible: false,
      tableParams: {
        className: "report-table",
        maxHeight: "400px"
      },
      // 性别-年龄
      genderAgeTab: "gender_age",
      genderAgeTabs: [
        { title: "性别年龄", value: "gender_age" },
        { title: "性别", value: "gender" },
        { title: "年龄", value: "age" }
      ]
    };
  },
  computed: {
    ...mapState("chartDrawer", ["colors", "showFields"]),
    ...mapGetters("chartDrawer", ["filteredFields"]),
    // 下载数据
    downLoadParams() {
      let obj = {
        account_id: this.$store.getters.accountId,
        action_attribution_windows: [],
        after: "",
        date_start: this.dateRange[0],
        date_end: this.dateRange[1],
        fields: this.colData(this.fixedFields).map(item => item.prop),
        filtering: [],
        object_filtering: [],
        keyword: "",
        level: this.curSelNode.level,
        page_length: 20,
        sort: [],
        ids: [this.curSelNode.id]
      };
      return obj;
    },
    fixedFields() {
      return {
        label: this.genderAgeTabs.find(item => item.value == this.genderAgeTab)
          .title,
        prop: "name",
        fixed: "left",
        width: "100px"
      };
    },
    colData() {
      return function(fixedFields) {
        let arr = this.filteredFields.map(item => {
          return {
            label: item.name,
            prop: item.keyName
          };
        });
        arr.forEach(item => {
          if (item.label && item.label.length <= 3) {
            item.minWidth = "100px";
          } else {
            item.minWidth = "115px";
          }
        });
        arr.unshift(fixedFields);
        // console.log("computed-colData", arr);
        return arr;
      };
    },
    createTableData() {
      return this.chartData;
    },
    mark() {
      let v = this.genderAgeTab;
      return v == "gender_age" ? ["gender", "age"] : [v];
    },
    activeTabComponent() {
      return (
        (this.genderAgeTab == "gender_age" ? "gender-age" : this.genderAgeTab) +
        "-chart"
      );
    }
  },
  methods: {
    handleCommand(c, index) {
      this.$emit("indicator-command", c, index);
    },
    breakdown() {
      this.$showLoading();
      let params = {
        account_id: this.$store.getters.accountId,
        action_attribution_windows: [],
        date_start: this.dateRange[0],
        date_end: this.dateRange[1],
        fields: this.filteredFields.map(item => item.keyName),
        level: this.curSelNode.level,
        breakdowns: this.mark,
        ids: [this.curSelNode.id]
      };
      return breakdown(JSON.stringify(params)).then(res => {
        this.$hideLoading();
        if (res.code == 0) {
          this.chartData = res.data.data.length ? [...res.data.data] : [];
        }
      });
    },
    // x坐标轴
    getAxisData() {
      this.$showLoading();
      let params = {
        breakdown_type: this.genderAgeTab,
        start_date: this.dateRange[0],
        end_date: this.dateRange[1]
      };
      return getAxisData(params).then(res => {
        this.$hideLoading();
        if (res.comment == "ok") {
          this.xAxisData = res.data.axis.axisName;
        }
        return res;
      });
    },
    openCusColumn() {
      this.cusColumnVisible = true;
    },
    async handleGetCusColumns(arr) {
      // await this.$store.dispatch("chartDrawer/setFields", { fields: arr });
      let arr2 = [];
      arr2 = this.filteredFields.slice(0, 2);
      await this.$store.dispatch("chartDrawer/setShowFields", {
        fields: arr2
      });
      this.breakdown();
    },
    async handleTabClick(tab) {
      // console.log("handleTabClick-tab", tab);
      await this.getAxisData();
      this.breakdown();
    }
  },
  async mounted() {
    await this.getAxisData();
    this.breakdown();
  },
  watch: {
    dateRange: {
      async handler(val) {
        // console.log("w-dateRange");
        if (val.length) {
          await this.getAxisData();
          this.breakdown();
        }
      },
      deep: true
    },
    curSelNode: {
      handler(val) {
        // console.log("w-curSelNode");
        this.breakdown();
      },
      deep: true
    }
  }
};
</script>

<style>
</style>