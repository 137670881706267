<template>
  <div>
    <!-- 时-日-周-月 -->
    <el-dropdown
      size="small"
      placement="bottom-start"
      class="sel-drop"
      @command="commandMark"
    >
      <p class="model-p dp-flex align-item-c">
        <span
          >{{ marks.find(item => item.value == mark).title
          }}<i class="el-icon-arrow-down el-icon--right"></i
        ></span>
      </p>
      <el-dropdown-menu slot="dropdown">
        <template v-for="(f, fi) in marks">
          <el-tooltip
            :key="fi"
            effect="light"
            :content="f.disable_reason"
            placement="right-end"
            :disabled="!f.disabled"
          >
            <span>
              <el-dropdown-item
                :key="fi"
                :command="f.value"
                :disabled="f.disabled"
              >
                <span>{{ f.title }}</span>
              </el-dropdown-item>
            </span>
          </el-tooltip>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    mark: {
      type: String,
      default: () => ""
    },
    marks: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    commandMark(c) {
      this.$emit("command-mark", c);
    }
  }
};
</script>

<style>
</style>