<template>
  <div class="pane-content bg-f">
    <div class="chart-area py-16 px-20">
      <p class="title mb-16">数据分析</p>
      <div class="dp-flex align-item-c">
        <!-- 展示指标 -->
        <show-indicator></show-indicator>
        <!-- 分日-周-月等 -->
        <time-type
          v-if="mark"
          :mark="mark"
          :marks="marks"
          @command-mark="commandMark"
        ></time-type>
      </div>
      <!-- chart-不是时间对比 -->
      <trend-chart
        v-if="!isCompare && xAxisData.length"
        :colors="colors"
        :chartData="chartData"
        :xAxisData="xAxisData"
        :fields="showFields"
        :mark="mark"
      ></trend-chart>
      <!-- chart-时间对比 -->
      <trend-chart-compare
        v-if="isCompare && comparexAxisData.length"
        :colors="colors"
        :chartData="compareChartData"
        :xAxisData="comparexAxisData"
        :fields="showFields"
        :mark="mark"
      ></trend-chart-compare>
    </div>
    <div class="blank bg-theme"></div>
    <!-- table 报表 -->
    <div class="table-area px-20">
      <div class="title py-16 dp-flex justify-btw align-item-c">
        <span>数据报表</span>
        <div class="dp-flex align-item-c cursor-pointer">
          <div class="downLoad mr-10">
            <down-load
              :downLoadParams="downLoadParams"
              tipContent="点击下载报表"
            ></down-load>
          </div>
          <!-- <p @click="openCusColumn" class="dp-flex align-item-c">
            <i class="el-icon-s-grid"></i><span>列</span>
          </p> -->
        </div>
      </div>
      <!-- table -->
      <cus-table
        v-if="mark"
        ref="tableRef"
        :tableParams="tableParams"
        :tableData="createTableData"
        :colData="colData(fixedFields)"
        :cuscolOtherOption="{ fields: filteredFields }"
        :getSummaries="getSummaries"
        :showSummary="true"
      >
        <template v-slot:colslot="{ scope, prop, otherOption }">
          <!-- <div v-if="prop == 'product_report'">
            <ad-cell-product-report
              :value="scope"
              :key="scope.row.level + scope.row.id + scope.row.name"
            ></ad-cell-product-report>
          </div>
          <div v-else-if="prop != 'budget'"> -->
          <div v-if="prop != 'budget'">
            {{
              cellFormat(
                scope.row[prop],
                scope.row[prop + "_desc"],
                prop,
                otherOption
              )
            }}
          </div>
        </template>
      </cus-table>
      <!-- 定制列 -->
      <cus-column-dialog
        :visible="cusColumnVisible"
        :initCheckedColumns="filteredFields.map(item => item.keyName)"
        :isSearchOpt="false"
        :hasFilterFields="true"
        @close-dialog="cusColumnVisible = false"
        @post-cus-columns="handleGetCusColumns"
      ></cus-column-dialog>
    </div>
  </div>
</template>

<script>
import showIndicator from "../showIndicator";
import timeType from "../timeType";
import trendChart from "../chart/trendChart";
import trendChartCompare from "../chart/trendChartCompare";
import downLoad from "@/views/adManagement/components/tableTopBar/downLoad.vue";
import cusColumnDialog from "@/views/adManagement/components/cusColumnDialog/index";
import cusTable from "@/views/components/cusTable";
import {
  breakdownRules,
  breakdown,
  getAxisData,
  getCompareDateDatas
} from "@/api/adManagement";
import { mapState, mapGetters } from "vuex";
// 经营评估
import AdCellProductReport from "@/views/adManagement/components/table/AdCellProductReport";
export default {
  components: {
    showIndicator,
    timeType,
    trendChart,
    trendChartCompare,
    downLoad,
    cusColumnDialog,
    cusTable,
    AdCellProductReport
  },
  props: {
    // 时间范围
    dateRange: {
      type: Array,
      default: () => []
    },
    curSelNode: {
      type: Object,
      default: () => {}
    },
    getSummaries: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    cellFormat: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    chainStart: {
      type: String,
      default: () => ""
    },
    isCompare: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      mark: "",
      marks: [],
      chartData: [],
      compareChartData: {},
      xAxisData: [],
      comparexAxisData: [],
      cusColumnVisible: false,
      tableParams: {
        className: "report-table",
        maxHeight: "400px"
      }
    };
  },
  computed: {
    ...mapState("chartDrawer", ["colors", "showFields"]),
    ...mapGetters("chartDrawer", ["filteredFields"]),
    // 下载数据
    downLoadParams() {
      let obj = {
        account_id: this.$store.getters.accountId,
        action_attribution_windows: [],
        after: "",
        date_start: this.dateRange[0],
        date_end: this.dateRange[1],
        fields: this.colData(this.fixedFields).map(item => item.prop),
        filtering: [],
        object_filtering: [],
        keyword: "",
        level: this.curSelNode.level,
        page_length: 20,
        sort: [],
        ids: [this.curSelNode.id]
      };
      return obj;
    },
    fixedFields() {
      let obj = this.marks.find(item => item.value == this.mark);
      return {
        label: obj ? obj.title : "",
        prop: this.mark,
        fixed: "left",
        width: "120px"
      };
    },
    colData() {
      return function(fixedFields) {
        let arr = this.filteredFields.map(item => {
          return {
            label: item.name,
            prop: item.keyName
          };
        });
        arr.forEach(item => {
          if (item.label && item.label.length <= 3) {
            item.minWidth = "100px";
          } else {
            item.minWidth = "115px";
          }
        });
        arr.unshift(fixedFields);
        return arr;
      };
    },
    createTableData() {
      if (!this.isCompare) {
        //不是时间对比
        return this.chartData;
      } else {
        // 时间对比
        let currentDatas = this.compareChartData.origin
          ? this.compareChartData.origin.data
          : [];
        let chainDatas = this.compareChartData.target
          ? this.compareChartData.target.data
          : [];
        let arr = [];
        currentDatas.forEach((item, index) => {
          arr.push(item);
          arr.push(chainDatas[index]);
        });
        return arr;
      }
    }
  },
  methods: {
    handleCommand(c, index) {
      this.$emit("indicator-command", c, index);
    },
    commandMark(c) {
      this.mark = c;
      if (!this.isCompare) {
        this.breakdown();
        this.getAxisData();
      } else {
        this.getCompareDateDatas();
      }
    },
    breakdownRules() {
      this.$showLoading();
      let params = {
        date_start: this.dateRange[0],
        date_end: this.dateRange[1]
      };
      return breakdownRules(params).then(res => {
        this.$hideLoading();
        if (res.comment == "ok") {
          // 周-日等
          let arr1 = res.data[0].rules;
          this.marks = [...arr1];
          // 默认可选的最后一项（倒序）
          let undisableArr = arr1.filter(item => !item.disabled);
          this.mark = undisableArr[undisableArr.length - 1].value;
        }
      });
    },
    breakdown() {
      this.$showLoading();
      let params = {
        account_id: this.$store.getters.accountId,
        action_attribution_windows: [],
        date_start: this.dateRange[0],
        date_end: this.dateRange[1],
        fields: this.filteredFields.map(item => item.keyName),
        level: this.curSelNode.level,
        breakdowns: [this.mark],
        ids: [this.curSelNode.id]
      };
      return breakdown(JSON.stringify(params)).then(res => {
        this.$hideLoading();
        if (res.code == 0) {
          this.chartData = res.data.data.length ? [...res.data.data] : [];
          // console.log("this.chartData", this.chartData);
        }
      });
    },
    getCompareDateDatas() {
      this.$showLoading();
      let params = {
        account_id: this.$store.getters.accountId,
        action_attribution_windows: [],
        date_start: this.dateRange[0],
        date_end: this.dateRange[1],
        chain_date_start: this.chainStart,
        fields: this.filteredFields.map(item => item.keyName),
        level: this.curSelNode.level,
        breakdowns: [this.mark],
        ids: [this.curSelNode.id]
      };
      getCompareDateDatas(params).then(async res => {
        this.$hideLoading();
        if (res.code == 0) {
          this.compareChartData = res.data ? { ...res.data } : {};
          // console.log("compare-this.compareChartData", this.compareChartData);
          // x轴坐标
          let xdata = [];
          let currentDatas = res.data.origin ? res.data.origin.data : [];
          let chainDatas = res.data.target ? res.data.target.data : [];
          if (currentDatas.length) {
            xdata = currentDatas.map(item => item[this.mark]);
          } else if (chainDatas.length) {
            xdata = chainDatas.map(item => item[this.mark]);
          } else {
            await this.getAxisData().then(res => {
              xdata = [...res.data.axis.axisName];
            });
          }
          this.comparexAxisData = xdata;
        }
      });
    },
    // x坐标轴
    getAxisData() {
      this.$showLoading();
      let params = {
        breakdown_type: this.mark,
        start_date: this.dateRange[0],
        end_date: this.dateRange[1]
      };
      return getAxisData(params).then(res => {
        this.$hideLoading();
        if (res.comment == "ok") {
          this.xAxisData = res.data.axis.axisName;
        }
        return res;
      });
    },
    openCusColumn() {
      this.cusColumnVisible = true;
    },
    async handleGetCusColumns(arr) {
      // await this.$store.dispatch("chartDrawer/setFields", { fields: arr });
      let arr2 = [];
      if (!this.isCompare) {
        arr2 = this.filteredFields.slice(0, 2);
        await this.$store.dispatch("chartDrawer/setShowFields", {
          fields: arr2
        });
        this.breakdown();
      } else {
        arr2 = this.filteredFields.slice(0, 1);
        await this.$store.dispatch("chartDrawer/setShowFields", {
          fields: arr2
        });
        this.getCompareDateDatas();
      }
    }
  },
  created() {
    this.$bus.$on("chain-start-change", () => {
      this.breakdownRules().then(res => {
        this.getCompareDateDatas();
      });
    });
  },
  async mounted() {
    await this.breakdownRules();
    this.breakdown();
    this.getAxisData();
  },
  watch: {
    dateRange: {
      handler(val) {
        // console.log("dateRange-change");
        this.breakdownRules().then(res => {
          if (!this.isCompare) {
            this.breakdown();
            this.getAxisData();
          } else {
            this.getCompareDateDatas();
          }
        });
      },
      deep: true
    },
    curSelNode: {
      handler(val) {
        // console.log("w-curSelNode");
        if (!this.isCompare) {
          this.breakdown();
        } else {
          this.getCompareDateDatas();
        }
      },
      deep: true
    },
    isCompare: {
      handler(val) {
        // console.log("iscompare-change");
        this.breakdownRules().then(res => {
          if (!val) {
            this.breakdown();
            this.getAxisData();
          } else {
            this.getCompareDateDatas();
          }
        });
      }
    }
  }
};
</script>

<style>
</style>