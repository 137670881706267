<template>
  <el-popover
    @show="showSearchIcon = true"
    @hide="showSearchIcon = false"
    :visible-arrow="false"
    placement="bottom-start"
    width="600"
    popper-class="tree-pop"
    trigger="click"
  >
    <div class="pr h100">
      <el-tree
        ref="treeRef"
        class="tree-wrap h100 overflow-auto"
        :props="props"
        node-key="id"
        :load="loadNode"
        @node-click="nodeClick"
        @node-expand="nodeExpand"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        lazy
        v-infinite-scroll="loadMore"
        :infinite-scroll-immediate="false"
        :infinite-scroll-disabled="infiniteScrollDisabled"
      >
      </el-tree>
      <!--  直接在el-tree上加v-loading时出现遮罩层只有el-tree组件高度的一半，故自定义加载遮罩 -->
      <div
        v-if="loading"
        class="pa dp-flex align-item-c justify-c color-theme"
        style="top:0;left:0;right:0;bottom:0;background-color:rgba(0, 0, 0, 0.2);"
      >
        加载中……
      </div>
    </div>
    <div slot="reference" class="dp-flex align-item-c cus-title">
      <el-input
        class="search-ipt"
        size="small"
        clearable
        placeholder="请输入"
        v-model="searchIpt"
        :style="{ width: textWidth(searchIpt) }"
      ></el-input>
      <i v-show="!showSearchIcon" class="el-icon-arrow-down"></i>
      <i
        @click="handleSearch(searchIpt)"
        v-show="showSearchIcon"
        class="el-icon-search"
      ></i>
    </div>
  </el-popover>
</template>

<script>
import { report } from "@/api/adManagement";
export default {
  props: {
    currentRow: {
      type: Object,
      default: () => {}
    },
    pageSize: {
      type: [Number, String]
    },
    list: {
      type: Array,
      default: () => []
    },
    initPageAfter: {
      type: String,
      default: () => ""
    },
    total: {
      type: Number,
      default: 100
    },
    dateStart: {
      type: String,
      default: () => ""
    },
    dateEnd: {
      type: String,
      default: () => ""
    },
    fields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchIpt: "",
      showSearchIcon: false,
      props: {
        label: "name",
        isLeaf: (data, node) => {
          if (data.level == "ad") {
            return true;
          }
        }
      },
      pageAfter: "",
      firstLevelList: [],
      loading: false,
      nomore: false,
      node: [],
      resolve: [],
      otherList: [],
      isExpand: false
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.loading || this.nomore || this.total < 8 || this.isExpand;
    },
    // input宽度随输入内容动态改变
    textWidth() {
      return function(value) {
        if (!value) {
          return "100%";
        } else {
          return String(value).length * 14 + 50 + "px";
        }
      };
    },
    comParams() {
      return {
        page_length: this.pageSize,
        date_start: this.dateStart,
        date_end: this.dateEnd,
        filtering: [],
        account_id: this.$store.getters.accountId,
        keyword: "",
        fields: this.fields,
        action_attribution_windows: [],
        sort: []
      };
    }
  },
  methods: {
    handleSearch(searchIpt) {
      this.$refs.treeRef.filter(searchIpt);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) != -1;
    },
    nodeClick(obj, node) {
      // console.log("nodeClick");
      this.searchIpt = obj.name;
      this.$emit("post-sel-node", obj);
    },
    nodeExpand(a, b, c) {
      // console.log("nodeExpand(a,b,c)", a, b, c);
      // 节点展开时禁止触发loadmore
      this.isExpand = true;
    },
    // 加载子节点
    loadNode(node, resolve) {
      // console.log("loadNode(node, resolve)", node, resolve);
      this.node = node;
      this.resolve = resolve;
      if (node.level == 0) {
        return resolve([...this.firstLevelList]);
      } else if (node.level >= 1) {
        if (node.data.level == "ad") {
          return resolve([]);
        } else {
          this.getOtherLevelList(node.data).then(arr => {
            return resolve(arr);
          });
        }
      }
    },
    // 滚动加载
    loadMore() {
      // console.log("loadMore");
      this.getFirstLevelList();
    },
    getFirstLevelList() {
      this.loading = true;
      let params = {
        level: this.currentRow.level,
        after: this.pageAfter,
        ...this.comParams,
        object_filtering: []
      };
      report(JSON.stringify(params)).then(res => {
        this.loading = false;
        if (res.comment == "ok") {
          if (res.data.data.length) {
            this.firstLevelList.push(...res.data.data);
          } else {
            this.nomore = true;
          }
          this.node.childNodes = [];
          this.loadNode(this.node, this.resolve);
          this.pageAfter = res.data.paging.after;
        }
      });
    },
    // 广告系列-广告组-广告-列表
    getOtherLevelList(obj) {
      let level =
        obj.level == "campaign" ? "adset" : obj.level == "adset" ? "ad" : "";
      let params = {
        level,
        after: "",
        ...this.comParams,
        fields: ["id", "name"],
        object_filtering: [
          { field: `${obj.level}.id`, operator: "IN", value: [obj.id] }
        ]
      };
      if (level) {
        return report(JSON.stringify(params)).then(res => {
          if (res.comment == "ok") {
            return res.data.data;
          }
        });
      }
    }
  },
  mounted() {
    this.$refs.treeRef.setCurrentKey(this.currentRow.id);
  },
  watch: {
    currentRow: {
      handler(val) {
        this.searchIpt = val.name;
      },
      deep: true,
      immediate: true
    },
    list: {
      handler(val) {
        this.firstLevelList = [...val];
      },
      deep: true,
      immediate: true
    },
    initPageAfter: {
      handler(val) {
        this.pageAfter = val;
      },
      immediate: true
    },
    firstLevelList: {
      handler(val) {
        // console.log("firstLevelList", val);
        if (val && val.length && val.length >= this.total) {
          this.nomore = true;
        } else {
          this.nomore = false;
        }
        // console.log("this.nomore", this.nomore);
      },
      deep: true
    },
    nomore(val) {
      if (val) {
        // console.log("w-nomore", val);
        this.$message({
          message: "抱歉，没有更多了!",
          center: true
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.cus-title {
  input {
    border: none;
    @extend .bg-theme;
    &,
    &::placeholder {
      color: rgb(38, 38, 38);
      font-size: 16px;
    }
  }
  i {
    color: rgba(0, 0, 0, 0.25);
  }
  .search-ipt input {
    @extend .text-a-l;
  }
}
.tree-pop {
  min-width: 295px;
  height: 260px;
  padding: 8px 4px 0;
}
.tree-wrap {
  color: $theme-color;
  .el-tree-node__expand-icon {
    @extend .color-26;
    @extend .bg-f;
  }
  .el-tree-node__content {
    margin-bottom: 8px;
    @extend .bg-f;
    .el-tree-node__label {
      padding: 0 4px;
      line-height: 24px;
      @extend .flex-1;
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
  .el-tree-node {
    &.is-current {
      & > .el-tree-node__content {
        & > .el-tree-node__label {
          background-color: #cceaff;
        }
      }
    }
    &:focus > .el-tree-node__content {
      @extend .bg-f;
    }
  }
  .is-leaf.el-tree-node__expand-icon {
    display: none;
  }
}
</style>